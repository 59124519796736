import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";

import asyncComponent from "util/asyncComponent";

const App = ({ match }) => (
  <div className="gx-main-content-wrapper">
    <Switch>
      {/* <Route path={`${match.url}dashboard`} component={asyncComponent(() => import('./Dashboard'))} /> */}
      <Route path={`${match.url}c2b`} component={asyncComponent(() => import('./pages/c2b'))} />
      <Route path={`${match.url}ussd`} component={asyncComponent(() => import('./pages/ussd'))} />
      <Route path={`${match.url}reverse`} component={asyncComponent(() => import('./pages/reverse.jsx'))} />
      <Route path={`${match.url}b2c`} component={asyncComponent(() => import('./pages/b2c.jsx'))} />
      <Route path={`${match.url}pending`} component={asyncComponent(() => import('./pages/markAsPending.jsx'))} />
      <Route path={`${match.url}failed`} component={asyncComponent(() => import('./pages/updateToFailed.jsx'))} />
      <Redirect to="/c2b" />
    </Switch>
  </div>
);

export default App;
